<template>
    <div
        class="columns"
        :class="{
            'columns--reversed': isReversed
        }"
        :data-column-count="slotNames.length"
    >
        <div
            v-for="slotName in slotNames"
            :key="slotName"
            class="columns__column"
        >
            <slot :name="slotName" />
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    isReversed: {
        type: Boolean,
        default: false
    }
});

const slots = useSlots();

const slotNames = computed(() => {
    return Object.keys(slots);
});
</script>

<style lang="less" src="./BaseColumns.less" />
